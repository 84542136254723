import vars from "./variables";
import * as utils from "./utils";

import {
  blPaddingX,
  blPaddingY,
  blWidth,
  blLabelHeight,
  paddingY,
  labelColWidth,
  rowHeight,
  colWidth,
  cellsPerDay,
  cellWidth,
  labelRowHeight,
  chartRight,
  y0,
  x0,
  calendarStart,
  weekday,
  times,
  rheight,
} from "./constants";

const drawDraggedRun = (ctx) => {
  if (vars.runBeingDraggedIdx >= 0) {
    const w = 80;
    const h = 40;
    ctx.fillStyle = "rgba(0,0,0,0.2)";
    utils.roundRect(
      ctx,
      vars.dragPos.x - w / 2,
      vars.dragPos.y - h / 2,
      w,
      h,
      15,
      true,
      true
    );
  }
};

function drawTextWithWrap(
  ctx,
  text,
  fontSize,
  fontColor,
  x,
  y,
  max_width,
  bold
) {
  const lines = [];
  let width = 0,
    i,
    j;
  let result;
  let color = fontColor || "white";

  // Font and size is required for ctx.measureText()
  ctx.font = (bold ? "bold " : "") + fontSize + "px Arial";

  // Start calculation
  while (text.length) {
    for (
      i = text.length;
      ctx.measureText(text.substr(0, i)).width > max_width;
      i--
    );

    result = text.substr(0, i);

    if (i !== text.length)
      for (
        j = 0;
        result.indexOf(" ", j) !== -1;
        j = result.indexOf(" ", j) + 1
      );

    lines.push(result.substr(0, j || result.length));
    width = Math.max(width, ctx.measureText(lines[lines.length - 1]).width);
    text = text.substr(lines[lines.length - 1].length, text.length);
  }

  ctx.font = (bold ? "bold " : "") + fontSize + "px Arial";

  // Render
  ctx.fillStyle = color;
  for (i = 0, j = lines.length; i < j; ++i) {
    ctx.fillText(lines[i], x, y + fontSize + (fontSize + 5) * i);
  }
}

const drawTooltip = (ctx) => {
  if (vars.runForMouseOverIdx >= 0) {
    const w = 200;
    const h = 250;
    const r = vars.runs[vars.runForMouseOverIdx];

    let y = utils.getYForRun(r) + vars.deltaY - h;
    const x = vars.dragPos.x; // utils.dateToPxl(r.startDate) + vars.deltaX;

    ctx.fillStyle = "rgba(220,220,220,0.9)";
    utils.roundRect(ctx, x - 4, y - 5, w + 8, h, 15, true, true);

    y += 5;
    drawTextWithWrap(ctx, "Run Name", 12, "black", x + 4, y, w - 8, true);
    y += 15;
    drawTextWithWrap(
      ctx,
      r.runName,
      12,
      "darkslategray",
      x + 4,
      y,
      w - 8,
      false
    );
    y += 25;

    drawTextWithWrap(ctx, "Requester", 12, "black", x + 4, y, w - 8, true);
    y += 15;
    drawTextWithWrap(
      ctx,
      r.requester,
      12,
      "darkslategray",
      x + 4,
      y,
      w - 8,
      false
    );
    y += 25;

    // new field
    drawTextWithWrap(ctx, "Project", 12, "black", x + 4, y, w - 8, true);
    y += 15;
    drawTextWithWrap(
      ctx,
      r.project,
      12,
      "darkslategray",
      x + 4,
      y,
      w - 8,
      false
    );
    y += 25;

    drawTextWithWrap(ctx, "Cycles", 12, "black", x + 4, y, w - 8, true);
    y += 15;
    drawTextWithWrap(
      ctx,
      `${r.r1Cycles}+${r.r2Cycles},${r.i1Cycles}+${r.i2Cycles}`,
      12,
      "darkslategray",
      x + 4,
      y,
      w - 8,
      false
    );
    y += 25;

    drawTextWithWrap(ctx, "Description", 12, "black", x + 4, y, w - 8, true);
    y += 15;
    drawTextWithWrap(
      ctx,
      r.description,
      12,
      "darkslategray",
      x + 4,
      y,
      w - 8,
      false
    );
    y += 25;
  }
};

const drawText = (ctx, txt, x, y, width) => {
  ctx.save();
  ctx.rect(x, y - 15, width - 12, 20);
  ctx.clip();
  ctx.fillText(txt, x, y);
  ctx.restore();
  return x + width;
};

const cols = [
  "Date Requested",
  "Run Name",
  "Requester",
  "Project",
  "Run Length",
  "Description",
];
const colWidths = [80, 80, 80, 80, 80, 100];

const drawRunInBacklog = (ctx, r, ty) => {
  let tx = blPaddingX + 5;
  ctx.fillStyle = "rgba(0,0,0,0.8)";
  ctx.font = "10px Roboto";
  ctx.fillText(
    `${new Date(r.dateRequested).toISOString().substring(0, 10)}`,
    tx,
    ty
  );
  tx += colWidths[0];
  tx = drawText(ctx, r.runName, tx, ty, colWidths[1]);
  tx = drawText(ctx, r.requester, tx, ty, colWidths[2]);
  tx = drawText(ctx, r.project, tx, ty, colWidths[3]);
  tx = drawText(ctx, r.runLength, tx, ty, colWidths[4]);
  tx = drawText(ctx, r.description, tx, ty, colWidths[5]);
  ty += rheight;
  return ty;
};

const drawRunsBacklog = (ctx) => {
  const height = vars.canvasHeight;
  vars.bottom = height - blPaddingY;
  ctx.beginPath();
  ctx.strokeStyle = "rgba(0, 0, 128, 0.6)";
  ctx.lineWidth = 0.75;
  ctx.moveTo(blPaddingX, blPaddingY + blLabelHeight); // label bottom horizontal
  ctx.lineTo(blPaddingX + blWidth, blPaddingY + blLabelHeight);

  ctx.stroke();

  ctx.lineWidth = 1.75;
  ctx.strokeStyle = "rgba(0, 0, 128, 0.4)";
  utils.roundRect(
    ctx,
    blPaddingX - 8,
    blPaddingY,
    blWidth + 16,
    height - 2 * blPaddingY,
    30,
    false,
    true
  );

  // draw columns
  ctx.save();
  ctx.rect(blPaddingX, blPaddingY, blWidth, height - 2 * blPaddingY);
  ctx.clip();

  ctx.fillStyle = "rgba(0, 0, 0, 0.65)";
  ctx.font = "14px Roboto";
  ctx.textAlign = "center";
  ctx.fillText("Runs Backlog", blPaddingX + blWidth / 2, blPaddingY + 20);

  ctx.strokeStyle = "rgba(0, 0, 128, 0.6)";
  ctx.lineWidth = 0.75;
  ctx.textAlign = "start";
  ctx.fillStyle = "rgba(0,0,0,0.8)";
  ctx.font = "500 10px Roboto";
  let tx = blPaddingX;
  let ty = blPaddingY + blLabelHeight + 30;
  ctx.beginPath();
  for (var i = 0; i < cols.length; i++) {
    ctx.fillText(`${cols[i]}`, tx, ty);

    if (i === cols.length - 1) break;
    tx += colWidths[i];
    ctx.moveTo(tx - 5, ty - 20); // label bottom horizontal
    ctx.lineTo(tx - 5, vars.bottom - 10);
  }
  ctx.moveTo(-100, vars.bottom - 79);
  ctx.lineTo(-100, vars.bottom - 50);

  ctx.stroke();

  ctx.restore();
  ctx.save();
  ty = blPaddingY + blLabelHeight + 30;
  ctx.rect(blPaddingX, ty, blWidth, vars.bottom - ty);
  ctx.clip();

  ty = blPaddingY + blLabelHeight + 35 + vars.backlogDeltaY;
  ctx.beginPath();
  ctx.strokeStyle = "rgba(0, 0, 128, 0.6)";
  ctx.lineWidth = 0.25;
  for (let r = 0; r < 1000; r++) {
    ctx.moveTo(blPaddingX, ty);
    ctx.lineTo(blPaddingX + blWidth, ty);

    if (r === vars.highlightedRow) {
      ctx.fillStyle = "rgba(0,0,0,0.04)";
      ctx.fillRect(blPaddingX, ty, blWidth + 5, rheight);
    }
    if (r === vars.selectedRow) {
      ctx.fillStyle = "rgba(0,0,100,0.2)";
      ctx.fillRect(blPaddingX, ty, blWidth + 5, rheight);
    }
    ty += rheight;

    if (ty > vars.bottom - 10) break;
  }

  ctx.stroke();

  ty = blPaddingY + blLabelHeight + 35 + 16 + vars.backlogDeltaY;
  vars.runs
    .filter(
      (x) =>
        x.instrumentID === 0 &&
        (!vars.showOnlyMyRuns || vars.currentUser === x.requester)
    )
    .forEach((r) => {
      ty = drawRunInBacklog(ctx, r, ty);
    });

  if (vars.copiedRun && vars.copiedRun.instrumentID !== 0)
    drawRunInBacklog(ctx, vars.copiedRun, ty);

  ctx.restore();
  ctx.fillStyle = "rgba(0, 0, 0, 0.65)";
};

const drawRun = (ctx, r) => {
  const y = utils.getYForRun(r) + vars.deltaY;
  const x = utils.dateToPxl(r.startDate) + vars.deltaX;
  const w = utils.hoursToPxls(r.runLength);
  ctx.fillStyle = r.color;

  if (r === vars.selectedRun) {
    ctx.strokeStyle = "rgba(0, 0, 0, 0.54)";
    ctx.lineWidth = 2;
  } else {
    ctx.strokeStyle = "rgba(0, 0, 0, 0.54)";
    ctx.lineWidth = 0.25;
  }

  utils.roundRect(ctx, x, y - 2, w, rowHeight / 2 - 5, 15, true, true);
  ctx.fillStyle = "black";
  ctx.font = "10px Roboto";
  drawText(ctx, `${r.runName} ${r.requester} `, x + 17, y + 12, w - 17);
  drawText(ctx, r.project, x + 17, y + 25, w - 17);
  drawText(ctx, r.description, x + 17, y + 38, w - 17);
};

const drawCalendar = (ctx) => {
  const chartBottom =
    paddingY + labelRowHeight + rowHeight * vars.instruments.length;

  ctx.fillStyle = "rgba(255,246,214, 1)";
  utils.roundRect(
    ctx,
    x0 + 1 * colWidth + vars.deltaX,
    paddingY,
    colWidth,
    chartBottom + vars.deltaY - paddingY,
    0,
    true,
    false
  );

  ctx.strokeStyle = "rgba(0, 0, 0, 0.54)";
  ctx.lineWidth = 0.25;

  ctx.beginPath();

  ctx.moveTo(x0 - labelColWidth, paddingY); // top horizontal
  ctx.lineTo(chartRight, paddingY);

  ctx.moveTo(x0, paddingY + labelRowHeight / 2); // middle horizontal
  ctx.lineTo(chartRight, paddingY + labelRowHeight / 2);

  ctx.moveTo(x0 - labelColWidth, paddingY + labelRowHeight); // bottom horizontal
  ctx.lineTo(chartRight, paddingY + labelRowHeight);

  ctx.moveTo(x0 - labelColWidth, paddingY); // edge vertical
  ctx.lineTo(x0 - labelColWidth, chartBottom + vars.deltaY);

  ctx.moveTo(x0, paddingY); // left vertical
  ctx.lineTo(x0, chartBottom + vars.deltaY);

  ctx.stroke();

  // draw columns
  ctx.save();
  ctx.rect(x0, 0, chartRight - x0, chartBottom);
  ctx.clip();

  const startDayIndex = Math.floor(-vars.deltaX / colWidth);
  const lastDayIndex = startDayIndex + 2000 / colWidth;
  for (var i = startDayIndex; i < lastDayIndex; i++) {
    const x = x0 + i * colWidth + vars.deltaX;
    const dt = utils.addDays(calendarStart, i + 1);

    ctx.fillStyle = "rgba(0, 0, 0, 0.65)";
    ctx.font = "12px Roboto";
    ctx.fillText(weekday[dt.getDay()], x + 15, paddingY + 16);
    ctx.font = "20px Roboto";
    ctx.fillText(dt.getDate().toString(), x + 15, paddingY + 16 + 20);

    ctx.beginPath();

    for (var c = 0; c < cellsPerDay; c++) {
      ctx.fillStyle = "rgba(0, 0, 0, 0.55)";
      ctx.font = "10px Roboto";
      ctx.fillText(
        times[c],
        x + c * cellWidth + 1,
        paddingY + 16 + labelRowHeight / 2 + 24
      );
      ctx.moveTo(x + c * cellWidth, paddingY + labelRowHeight / 2);
      ctx.lineTo(x + c * cellWidth, chartBottom + vars.deltaY);
    }

    ctx.moveTo(x + colWidth, paddingY);
    ctx.lineTo(x + colWidth, chartBottom + vars.deltaY);

    ctx.stroke();
  }
  ctx.restore();

  const numRows = vars.instruments.length;

  // draw rows
  ctx.save();
  ctx.rect(0, y0, chartRight, chartBottom - y0);
  ctx.clip();

  let y = 0;
  ctx.beginPath();
  for (var r = 0; r < numRows; r++) {
    y = y0 + r * rowHeight + vars.deltaY;
    ctx.fillStyle = "rgba(0, 0, 0, 0.85)";
    ctx.font = "14px Roboto";
    ctx.fillText(vars.instruments[r].name, x0 - labelColWidth + 10, y + 20);

    ctx.fillText("A", x0 - 13, y + 24);

    ctx.fillText("B", x0 - 13, y + 24 + rowHeight / 2);

    ctx.moveTo(x0 - labelColWidth, y);
    ctx.lineTo(chartRight, y);
    ctx.moveTo(x0 - labelColWidth, y + rowHeight);
    ctx.lineTo(chartRight, y + rowHeight);
    ctx.moveTo(x0, y + rowHeight / 2);
    ctx.lineTo(chartRight, y + rowHeight / 2);
  }
  ctx.stroke();
  ctx.restore();

  // draw vars.runs
  ctx.save();

  ctx.rect(x0, y0, chartRight - x0, chartBottom - y0);
  ctx.clip();

  const minDateDisplayed = utils.pxlToDate(x0 - vars.deltaX).getTime();

  vars.runs
    .filter(
      (x) =>
        x.instrumentID !== 0 &&
        x.startDate.getTime() + x.runLength * 1000 * 60 * 60 > minDateDisplayed // don't try to display runs that are not in the visible range
    )
    .forEach((r) => {
      drawRun(ctx, r);
    });

  if (vars.copiedRun && vars.copiedRun.instrumentID !== 0)
    drawRun(ctx, vars.copiedRun);

  ctx.restore();
};

const setCanvasResolution = (myCanvas) => {
  var PIXEL_RATIO = (function () {
    var ctx = document.createElement("canvas").getContext("2d") as any,
      dpr = window.devicePixelRatio || 1,
      bsr =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1;
    return dpr / bsr;
  })();
  if (myCanvas) {
    const width = vars.canvasWidth;
    const height = vars.canvasHeight;
    const can = myCanvas.current;
    const ratio = PIXEL_RATIO;
    can.width = width * ratio;
    can.height = height * ratio;
    can.style.width = width + "px";
    can.style.height = height + "px";
    can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
  }
};

const drawVScrollbarBacklog = (ctx) => {
  ctx.strokeStyle = "rgba(0, 0, 0, 1)";
  ctx.lineWidth = 0.5;
  const top = blPaddingY + blLabelHeight + 35;
  const bottom = vars.bottom - 10;
  const runsInBacklog = vars.runs.filter(
    (x) =>
      x.instrumentID === 0 &&
      (!vars.showOnlyMyRuns || vars.currentUser === x.requester)
  ).length;

  const visibleHeightInPixels = bottom - top;
  const totalHeightInPixels = runsInBacklog * rheight;
  let tabHeight =
    (visibleHeightInPixels / totalHeightInPixels) * visibleHeightInPixels;

  if (tabHeight > visibleHeightInPixels) tabHeight = visibleHeightInPixels;

  const tabPos =
    (-vars.backlogDeltaY / totalHeightInPixels) * visibleHeightInPixels + top;

  const x = blPaddingX + blWidth + 16;

  ctx.strokeStyle = "rgba(0, 0, 0, .6)";
  ctx.lineWidth = 0.5;
  ctx.fillStyle = "rgba(240, 240, 240, 1)";
  utils.roundRect(ctx, x, top, 16, visibleHeightInPixels, 0, true, true);

  ctx.fillStyle = "rgba(205, 205, 205, 1)";
  utils.roundRect(ctx, x, tabPos, 16, tabHeight, 0, true, true);

  ctx.strokeStyle = "rgba(0, 0, 0, .25)";
  ctx.lineWidth = 0.5;
  ctx.moveTo(x + 30, top);
  ctx.lineTo(x + 30, top + totalHeightInPixels);
  ctx.moveTo(x + 40, top);
  ctx.lineTo(x + 40, top + visibleHeightInPixels);
  ctx.stroke();
};

const drawVScrollbar = (ctx) => {
  ctx.strokeStyle = "rgba(0, 0, 0, 1)";
  ctx.lineWidth = 0.5;
  const visibleHeightInPixels = vars.canvasHeight - y0 - 1 - 16;
  const totalHeightInPixels = vars.instruments.length * rowHeight;
  const tabHeight =
    (visibleHeightInPixels / totalHeightInPixels) * visibleHeightInPixels;
  const tabPos =
    (-vars.deltaY / totalHeightInPixels) * visibleHeightInPixels + y0;

  ctx.fillStyle = "rgba(255, 255, 255, 1)";
  utils.roundRect(
    ctx,
    vars.canvasWidth - 16,
    paddingY,
    16,
    y0 - paddingY,
    0,
    true,
    false
  );

  ctx.fillStyle = "rgba(255, 255, 255, 1)";
  utils.roundRect(
    ctx,
    vars.canvasWidth - 16,
    vars.canvasHeight - 16,
    16,
    16,
    0,
    true,
    false
  );

  ctx.strokeStyle = "rgba(0, 0, 0, .15)";
  ctx.lineWidth = 0.5;
  ctx.moveTo(vars.canvasWidth - 16, paddingY);
  ctx.lineTo(vars.canvasWidth - 16, y0);
  ctx.stroke();

  ctx.strokeStyle = "rgba(0, 0, 0, .6)";
  ctx.lineWidth = 0.5;
  ctx.fillStyle = "rgba(240, 240, 240, 1)";
  utils.roundRect(
    ctx,
    vars.canvasWidth - 16,
    y0,
    16,
    vars.canvasHeight - y0 - 1 - 16,
    0,
    true,
    true
  );

  ctx.fillStyle = "rgba(205, 205, 205, 1)";
  utils.roundRect(
    ctx,
    vars.canvasWidth - 16,
    tabPos,
    16,
    tabHeight,
    0,
    true,
    true
  );
};

const drawHScrollbar = (ctx) => {
  const visibleWidthInPixels = vars.canvasWidth - x0 - 1 - 16;
  const totalWidthInPixels = 9 * colWidth;
  const tabWidth =
    (visibleWidthInPixels / totalWidthInPixels) * visibleWidthInPixels;
  const tabPos =
    (-(vars.deltaX - 400) / totalWidthInPixels) * visibleWidthInPixels + x0;

  ctx.strokeStyle = "rgba(0, 0, 0, .25)";
  ctx.lineWidth = 0.5;
  ctx.moveTo(x0 - labelColWidth, vars.canvasHeight - 16);
  ctx.lineTo(x0, vars.canvasHeight - 16);
  ctx.stroke();

  ctx.strokeStyle = "rgba(0, 0, 0, .6)";
  ctx.lineWidth = 0.5;

  ctx.fillStyle = "rgba(255, 255, 255, 1)";
  utils.roundRect(
    ctx,
    x0 - labelColWidth,
    vars.canvasHeight - 16,
    labelColWidth,
    16,
    0,
    true,
    false
  );

  ctx.fillStyle = "rgba(240, 240, 240, 1)";
  utils.roundRect(
    ctx,
    x0,
    vars.canvasHeight - 16,
    visibleWidthInPixels,
    16,
    0,
    true,
    true
  );

  ctx.fillStyle = "rgba(205, 205, 205, 1)";
  utils.roundRect(
    ctx,
    tabPos,
    vars.canvasHeight - 16,
    tabWidth,
    16,
    0,
    true,
    true
  );
};

const drawAll = (ctx) => {
  ctx.clearRect(0, 0, 5000, 5000);
  drawRunsBacklog(ctx);
  drawCalendar(ctx);

  ctx.strokeStyle = "rgba(0, 0, 128, .4)";
  ctx.lineWidth = 1;

  drawVScrollbar(ctx);
  drawHScrollbar(ctx);
  drawVScrollbarBacklog(ctx);

  utils.roundRect(
    ctx,
    x0 - labelColWidth,
    paddingY,
    vars.canvasWidth - (x0 - labelColWidth) - 1,
    vars.canvasHeight - paddingY - 1,
    5,
    false,
    true
  );

  drawDraggedRun(ctx);
  drawTooltip(ctx);
};

export { drawAll, setCanvasResolution };
