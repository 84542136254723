export interface BatchInfo {
  numCycles: number;
  pmgMask: string;
  indexMask: string;
}

export interface SpatialSequencingPrimerTube {
  BatchName: string;
  PMGMask?: string;
  BarcodeMask?: string;
  MinCycles?: number;
  DefaultMismatch?: number;
}

export interface SpatialTarget {
  BatchName: string;
  Target?: string;
  TargetType?: string;
  Barcode?: string;
  ControlType?: string;
}

export interface SpatialPanel {
  Name: string;
  Application: string;
  SequencingPrimerTubes?: SpatialSequencingPrimerTube[];
  Targets?: SpatialTarget[];
  PanelCartridgePartNumbers?: string[];
}

export interface PlannedRun {
  id: number;
  runID: number;
  runCountNumber: number;
  instrumentID: number;
  side: string;
  groupID: number;
  runName: string;
  flowcell: string;
  operator: string;
  requester: string;
  project: string;
  notificationEmails: string;
  runLength: number;
  kitConfiguration: string;
  manifest: string;
  description: string;
  libraryID: number;
  libraryConcentration: number;
  spikeInLibraryID: number;
  spikeInLibraryConcentration: number;
  libraryDilutionLot: string;
  libraryMixStockLot: string;

  l2LibraryID: number;
  l2LibraryConcentration: number;
  l2SpikeInLibraryID: number;
  l2SpikeInLibraryConcentration: number;
  l2LibraryDilutionLot: string;
  l2LibraryMixStockLot: string;

  analysisEnabled: boolean;
  cloudAnalysis: boolean;
  roiWidth: number;
  roiHeight: number;
  analysisToolRep: string;
  imageName: string;
  arguments: string;
  logLevel: string;
  uploadIntensities: boolean;
  saveIntensities: string;
  noDelete: boolean;
  startDate: Date;
  dateRequested: Date;
  tags: string[];
  color: string;
  i1Cycles: number;
  i2Cycles: number;
  r1Cycles: number;
  r2Cycles: number;
  tileSelectionModule: string;
  preparationMethod: string;
  kitSize: string;
  readOrder: string;
  recipeBranch: string;
  libraryType: string;
  chemistryVersion: string;
  dualLibrary: boolean;
  singleLane: boolean;
  targetedReads: string;
  kitType: string;
  runType: "Sequencing" | "Spatial";
  spatialNumBatches: number;
  batchInfo: BatchInfo[];
  panelFilePath: string;
  pmgMask: string;
  spatialPanelJson: string; // direct JSON representation of the spatial panel, this is what is used by kiosk
  spatialPanel: string; // temporary holding for CSV or spatial panel viewing
  numSpatialZSlices: number;
  zSliceOffset: number;
  zSlicePitch: number;
  spatialPanelType: "CSV" | "JSON" | ""; // Denotes whether the spatialPanelJson is CSV or JSON type
  spatialCartridgePartNumber: string;
  wellLayout: string;
  sequencingPanelID: string;
  zipCNNImages: boolean;

  pmgThreshold: number;
  cytoSmallCell: boolean;
}

const blPaddingX = 10;
const blPaddingY = 80;
const blWidth = 487;
const blLabelHeight = 30;

const paddingY = 20;
const paddingX = blPaddingX + blWidth + 50;
const labelColWidth = 100;

const rowHeight = 100;

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const times = ["0:00", "06:00", "12:00", "18:00"];
const rheight = 20;

const colWidth = 200;
const cellsPerDay = times.length;
const cellWidth = colWidth / cellsPerDay;

const numDaysToShow = 9;
const labelRowHeight = 90;
const chartRight = paddingX + labelColWidth + colWidth * numDaysToShow;

const pxlsPerMinute = colWidth / (24 * 60.0);
const y0 = paddingY + labelRowHeight;
const x0 = paddingX + labelColWidth;

const currentDate = new Date();
const calendarStart = new Date(
  new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  ).getTime() -
    (7 + 24) * 60 * 60 * 1000
);

const newRun: PlannedRun = {
  id: null,
  dateRequested: new Date(),
  groupID: 0,
  runCountNumber: 0,
  runID: null,
  instrumentID: 0,
  side: "A",
  flowcell: "",
  libraryDilutionLot: "",
  libraryMixStockLot: "",
  libraryID: null,
  libraryConcentration: null,
  spikeInLibraryID: 0,
  spikeInLibraryConcentration: 0.0,

  l2LibraryDilutionLot: "",
  l2LibraryMixStockLot: "",
  l2LibraryID: null,
  l2LibraryConcentration: null,
  l2SpikeInLibraryID: 0,
  l2SpikeInLibraryConcentration: 0.0,

  startDate: new Date(),
  tags: [],
  color: "black",
  runName: "",
  operator: "",
  requester: "",
  project: "",
  notificationEmails: "",
  runLength: 40,
  kitConfiguration: "300Cycles",
  description: "",
  manifest: `[RunParameters]
ParameterName,Value
#SequencingRecipePath,p2/xxx.star
#PrimingRecipePath,p2/xxx_priming.star`,
  analysisEnabled: true,
  cloudAnalysis: false,
  logLevel: "Info",
  uploadIntensities: false,
  saveIntensities: "none",
  noDelete: false,
  roiWidth: 5472,
  roiHeight: 3648,
  analysisToolRep:
    "registry.gitlab.com/elembio/analysis/primaryanalysisdotnetcore",
  imageName: "registry.gitlab.com/elembio/analysis/primaryanalysisdotnetcore:3",
  arguments: "bash /primaryanalysisdotnetcore/run_container_analysis.sh",
  i1Cycles: 0,
  i2Cycles: 0,
  r1Cycles: 151,
  r2Cycles: 151,
  tileSelectionModule: null,
  preparationMethod: "adept",
  kitSize: "300Cycles",
  readOrder: "I1,I2,R1,R2",
  recipeBranch: null,
  libraryType: null,
  chemistryVersion: null,
  dualLibrary: false,
  singleLane: false,
  targetedReads: "High",
  kitType: "ThreeHundredCycles",
  runType: "Sequencing",
  spatialNumBatches: 7,
  batchInfo: Array.from({ length: 7 }, () => ({
    numCycles: 12,
    pmgMask: "Y4N*",
    indexMask: "N4Y8N*",
  })),
  panelFilePath: "",
  pmgMask: "",
  spatialPanelJson: "",
  spatialPanel: "",
  numSpatialZSlices: 7, // default to 7
  zSliceOffset: 2,
  zSlicePitch: -1,
  spatialPanelType: "",
  spatialCartridgePartNumber: "",
  wellLayout: "",
  pmgThreshold: undefined,
  sequencingPanelID: "",
  zipCNNImages: false,

  cytoSmallCell: false,
};

const defaultSpatialManifest = `[RunParameters]
ParameterName,Value
#SpatialRecipePath,p2/xxx.star
#SpatialPrimingRecipePath,p2/xxx_priming.star
#SpatialFlowcellPressureCheckRecipePath,chem/leak_test.star`;

export {
  blPaddingX,
  blPaddingY,
  blWidth,
  blLabelHeight,
  paddingY,
  labelColWidth,
  rowHeight,
  colWidth,
  cellsPerDay,
  cellWidth,
  labelRowHeight,
  chartRight,
  pxlsPerMinute,
  y0,
  x0,
  calendarStart,
  weekday,
  times,
  newRun,
  rheight,
  defaultSpatialManifest,
};
